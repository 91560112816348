import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Modal from '../../../sharedComponents/Modal'
import closeIcon from '../../../assets/images/cancel.svg';
import { classify } from '../../../common/Utility';

function IdeasContentModal({ show, closeModal }) {
    const { currentIdeaContent } = useSelector(state => state.bucketList);
    return (
        <Modal show={show} onClose={closeModal} className={classify(['p-8'])}>
            <div className="flex justify-end">
                <button className='outline-none border-none' onClick={closeModal}>
                    <img className='w-9 h-9' src={closeIcon} alt="close" />
                </button>
            </div>
            <div className='container flex flex-col items-center px-4'>
                <h1 className='text-[24px]'>
                    Trip Content
                </h1>
                <div className="grid grid-cols-12 gap-y-2">
                    <div className="col-span-3 px-2 font-bold border border-black text-base">Trip Title:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                        {currentIdeaContent?.title}
                    </div>
                    <div className="col-span-3 px-2 font-bold border border-black text-base">Location:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                        {currentIdeaContent?.location}
                    </div>

                    <div className="col-span-3 px-2 font-bold border border-black text-base">Travel Dates:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                        {currentIdeaContent?.travel_dates}
                    </div>

                    <div className="col-span-3 px-2 font-bold border border-black text-base">Duration:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                        {currentIdeaContent?.duration}
                    </div>

                    <div className="col-span-3 px-2 font-bold border border-black text-base">Cost Per Person:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                        {currentIdeaContent?.cost_per_person}
                    </div>

                    <div className="col-span-3 px-2 font-bold border border-black text-base">Group Goal Code:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                        {currentIdeaContent?.group_goal_code}
                    </div>

                    <div className="col-span-3 px-2 font-bold border border-black text-base">Members Count:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                        {currentIdeaContent?.group_members_count}
                    </div>

                    <div className="col-span-3 px-2 font-bold border border-black text-base">Extra Perks:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                        {currentIdeaContent?.extras}
                    </div>
                    <div className="col-span-3 px-2 font-bold border border-black text-base">Influencer Recomended:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                        {currentIdeaContent?.is_recommended ? "Yes" : "No"}
                    </div>
                    {currentIdeaContent?.is_recommended && (
                        <>
                            <div className="col-span-3 px-2 font-bold border border-black text-base">Favourite Places:</div>
                            <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                                {currentIdeaContent?.favourite}
                            </div>
                            <div className="col-span-3 px-2 font-bold border border-black text-base">Places to Skip:</div>
                            <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                                {currentIdeaContent?.skip_places}
                            </div>
                        </>
                    )}
                    <div className="col-span-3 px-2 font-bold border border-black text-base">Influencers:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                        {currentIdeaContent?.influencers}
                    </div>
                    <div className="col-span-3 px-2 font-bold border border-black text-base">On the Bucket List:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base" dangerouslySetInnerHTML={{ __html: currentIdeaContent?.highlight }} />
                    <div className="col-span-3 px-2 font-bold border border-black text-base">Places to Visit:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base">
                        {currentIdeaContent?.places}
                    </div>
                    <div className="col-span-3 px-2 font-bold border border-black text-base">Experiences:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base grid grid-cols-12">
                        {currentIdeaContent?.experiences?.map((ele,id) => {
                            return(
                                <>
                                    <div className="col-span-2 border-r border-b border-black text-sm flex items-center">{id+1}.{' '}{ele?.title}</div>
                                    <div className="col-span-10 border-b border-black text-sm">{ele?.description}</div>
                                    <div className="col-span-12 mb-1 border-b border-black hidden">
                                        <img src={ele?.image} height={120} width={150} className='object-contain' />
                                    </div>
                                </>
                            )
                        })}
                    </div>

                    <div className="col-span-3 px-2 font-bold border border-black text-base">Itinearies:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base grid grid-cols-12">
                        {currentIdeaContent?.detailed_itinerary?.map((ele, id) => {
                            const { day } = ele;
                            if(day === -1) {
                                return (
                                    <>
                                        <div className="col-span-2 border-r border-b border-black text-sm flex items-center">Inclusions</div>
                                        <div className="col-span-10 border-b border-black text-sm whitespace-break-spaces">
                                            <ul className="list-disc pl-4">
                                                {ele?.inclusion?.map((activity, index) => (
                                                    <li key={index}>{activity}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="col-span-2 border-r border-b border-black text-sm flex items-center">Exclusions</div>
                                        <div className="col-span-10 border-b border-black text-sm whitespace-break-spaces">
                                            <ul className="list-disc pl-4">
                                                {ele?.exclusion?.map((activity, index) => (
                                                    <li key={index}>{activity}</li>
                                                ))}
                                            </ul>
                                            
                                        </div>
                                    </>  
                                )
                            }
                            return (
                                <>
                                    <div className="col-span-2 border-r border-b border-black text-sm flex flex-col justify-center whitespace-break-spaces">
                                        <span className='underline'>Day{day}</span>{'\n'}{ele?.title.trim()}
                                    </div>
                                    <div className="col-span-10 border-b border-black text-sm">
                                        <ul className="list-disc pl-4">
                                            {ele?.activities?.map((activity, index) => (
                                                <li key={index}>{activity}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="col-span-12 mb-1 border-b border-black">
                                        <img src={ele?.image_url} height={120} width={150} className='object-contain' />
                                    </div>
                                </>
                            )
                        })}
                    </div>

                    <div className="col-span-3 px-2 font-bold border border-black text-base">FAQs:</div>
                    <div className="col-span-9 overflow-auto px-1 border border-black text-base flex flex-col">
                        {currentIdeaContent?.faqs?.map((ele, id) => {
                            return (
                                <>
                                    <div className='flex flex-col gap-y-2 border-b border-black'>
                                        <div className=" text-sm flex items-center">{id + 1}.{' '}{ele?.question}</div>
                                        <div className="text-sm">{ele?.answer}</div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default IdeasContentModal